import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private router: Router) {
  }
  // canActivate() {
  //   var token = localStorage.getItem("jwt");
  //   var code = localStorage.getItem("code");

  //   if (token && code && !this.jwtHelper.isTokenExpired(token)){
  //     return true;
  //   }
  //   this.router.navigate(["/login"]);
  //   return false;
  // }

  canActivate() {
    const aduser = localStorage.getItem('ad-user');

    if (aduser){
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
