import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor() {}

  public changeTheme(dark: boolean) {
    document.body.classList.toggle('dark', dark);
    localStorage.setItem("theme", "" + dark);
  }

  public themeIsDark() {
    // https://stackoverflow.com/questions/5219105/javascript-parsing-a-string-boolean-value
    // if you know how make more beautiful solution, make it
    let a = localStorage.getItem("theme") || "false";
    return a == "true";
  }

  public getLogo(): string {
    const isDark = this.themeIsDark();
    if (isDark) {
      return '../assets/imgs/logo-white.png';
    }else {
      return '../assets/imgs/logo2.png';
    }
  }
}
