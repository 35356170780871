import { Component } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';

export interface ToastData {
  header: string;
  subHeader: string;
  confirmButtonText: string;
}

@Component({
  selector: 'app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent{
  public data: ToastData = {
    header: '',
    subHeader: '',
    confirmButtonText: ''
  };

  constructor(private animationCtrl: AnimationController) {
  }

  public showToast(data: ToastData ):void{
    const animation = this.animationCtrl.create()
      .addElement(document.querySelector('.overlay'))
      .duration(200)
      .fromTo('transform', 'translateX(0px)', 'translateX(-800px)')
      .fromTo('opacity', '0', '1');

    if(data) {
      this.data = data;
      animation.play();
    }
  }

  public hideToast():void{
    const animation = this.animationCtrl.create()
      .addElement(document.querySelector('.overlay'))
      .duration(200)
      .fromTo('transform', 'translateX(-800px)', 'translateX(-1600px)')
      .fromTo('opacity', '1', '0');

    animation.play();
  }
}


