import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Login } from '../models/login';
import { Observable, of } from 'rxjs';
import { AdUser, UserInfo } from '../models/ad-user.model';
import { map } from 'rxjs/operators';
import { EmailSettings } from '../models/email-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.mobileApi;
  constructor(private http: HttpClient, private router: Router, public toastController: ToastController) {}

  login(model: Login) {
    this.http.post(this.baseUrl + 'api/auth/login', { username: model.username, password: model.password }).subscribe(
      (response: any) => {
        const token = response.token;
        const code = response.code;
        const isHead = response.isHead;
        const isTeacher = response.isTeacher;
        const group = response.group;
        localStorage.setItem('jwt', token);
        localStorage.setItem('code', code);
        localStorage.setItem('isHead', isHead);
        localStorage.setItem('isTeacher', isTeacher);
        localStorage.setItem('group', group);
        this.router.navigate(['/']);
      },
      err => {
        this.presentToast(err.error);
      }
    );
  }

  public loginByAd(login: string, password: string): Observable<AdUser> {
    return this.http.post<AdUser>(`${this.baseUrl}api/User`, { login, password }).pipe(
      map(x => {
        if (x) {
          localStorage.setItem('ad-user', JSON.stringify(x));
          localStorage.setItem('jwt', x.token);
        }

        return x;
      })
    );
  }

  public getUserData() {
    const data = localStorage.getItem('ad-user');
    return JSON.parse(data) as AdUser;
  }

  public async isAdmin() {
    const res = await this.http.get<{ admin: boolean }>(this.baseUrl + 'api/user/isadmin').toPromise();
    return res?.admin || false;
  }

  public getUserInfo(): UserInfo {
    const data = this.getUserData();

    const userInfo: UserInfo = { fio: '', roles: [] };

    if (data.canVote) {
      userInfo.roles.push('Voter');
    }

    for (const i of data.distinguishedName.split(',')) {
      const component = i.split('=');
      if (component[0].toLocaleUpperCase() === 'CN') {
        userInfo.fio = component[1];
      }

      if (component[0].toLocaleUpperCase() === 'OU') {
        if (component[1].toLocaleLowerCase() === '_teachers') {
          userInfo.roles.push('Teacher');
        }

        if (component[1].toLocaleLowerCase() === '_students') {
          userInfo.roles.push('Student');
        }
      }
    }

    return userInfo;
  }

  public checkEmailSettings() {
    const emailSettings = localStorage.getItem('email-settings');
    if (!emailSettings) {
      return null;
    }
    return JSON.parse(emailSettings);
  }

  public getEmailSettings() {
    return this.http.get<EmailSettings>(this.baseUrl + 'api/user/email').pipe(
      map(x => {
        if (x) {
          localStorage.setItem('email-settings', JSON.stringify(x));
        }
        return x;
      })
    );
  }

  public updateEmail(m: EmailSettings) {
    return this.http.patch<EmailSettings>(this.baseUrl + 'api/user/email', m).pipe(
      map(x => {
        if (x) {
          localStorage.setItem('email-settings', JSON.stringify(x));
        }
        return x;
      })
    );
  }

  // adminLogin(login: string) {
  //   this.http
  //     .post(
  //       this.baseUrl + "api/auth/adminLogin",
  //       { login: login, key: 'KiTHuEpLiT' },
  //       {
  //         headers: new HttpHeaders({
  //           "Access-Control-Allow-Origin": "https://schedule.uni-dubna.ru/",
  //           "Content-Type": "application/json"
  //         })
  //       }
  //     )
  //     .subscribe(
  //       response => {
  //         console.log(response);
  //         let token = (<any>response).token;
  //         let code = (<any>response).code;
  //         let isHead = (<any>response).isHead;
  //         let isTeacher = (<any>response).isTeacher;
  //         let group = (<any>response).group;
  //         localStorage.setItem("jwt", token);
  //         localStorage.setItem("code", code);
  //         localStorage.setItem("isHead", isHead);
  //         localStorage.setItem("isTeacher", isTeacher);
  //         localStorage.setItem("group", group);
  //         this.router.navigate(["/"]);
  //       },
  //       err => {
  //         this.presentToast(err.error);
  //       }
  //     );
  // }

  logout() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('code');
    localStorage.removeItem('isHead');
    localStorage.removeItem('isTeacher');
    localStorage.removeItem('group');
    localStorage.removeItem('ad-user');
    localStorage.removeItem('scheduleTeacher');
    localStorage.removeItem('scheduleStudent');
    localStorage.removeItem('menu-cache');
    this.router.navigate(['/login']);
    location.reload();
  }

  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
    });
    toast.present();
  }
}
