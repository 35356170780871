import { ThemeService } from './../../services/theme.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AvatarService } from 'src/app/services/avatar.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() avatar = true;
  @Input() exit = false;
  @Input() text = '';
  @Input() public backUrl = '/';
  @Input() backButton : boolean = false;
  @Output() goBackFunction: EventEmitter<void> = new EventEmitter<void>();

  public urls: string[] = [];
  public iconPath: string;

  constructor(
    private avatarService: AvatarService,
    private location: Location,
    public themeService: ThemeService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const isDark = this.themeService.themeIsDark();
    this.setNewTheneIconPath(isDark);
  }

  getAvatar() {
    return this.avatarService.getAvatar();
  }

  goBack() {
    if (this.goBackFunction.observers.length > 0) {
      this.goBackFunction.emit();
      return;
    }
    this.location.back();
  }

  public changeTheme() {
    const isDark = this.themeService.themeIsDark();

    this.themeService.changeTheme(!isDark);
    this.setNewTheneIconPath(!isDark);
  }

  public setNewTheneIconPath(isDark: boolean) {
    if (isDark) {
      this.iconPath = 'assets/icon/sun.svg';
    } else {
      this.iconPath = 'assets/icon/moon.svg';
    }
  }

  public logout() {
    this.userService.logout();
  }
}
