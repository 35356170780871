import { ExamResultDto } from './../../services/exam-card.service';
import { Component, Input, OnInit } from '@angular/core';
import { ExamResult } from 'src/app/services/exam-card.service';

@Component({
  selector: 'app-exam-subject',
  templateUrl: './exam-subject.component.html',
  styleUrls: ['./exam-subject.component.scss'],
})
export class ExamSubjectComponent implements OnInit {

  @Input() public data: ExamResultDto;
  @Input() public index: number;

  constructor() {}

  public ngOnInit() {
    this.index++;
    console.log(this.data);
  }

  public clr(isReverse: boolean = false) {
    const bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue('--ion-color-primary')
  }
}

