import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
})
export class SubjectComponent implements OnInit {
  @Input() number: number;
  @Input() name: string;
  @Input() type: string;
  @Input() color: 'secondary' | 'primary' = 'secondary';

  constructor() {}

  ngOnInit() {}

  public getName(): string {
    return this.name || '—';
  }

  public clr(isReverse: boolean = false) {
    const bodyStyles = window.getComputedStyle(document.body);
    //if (this.color === 'secondary' && !isReverse || this.color !== 'secondary' && isReverse ) {
    //   return bodyStyles.getPropertyValue('--ion-color-secondary');
    // } else {
    //   return bodyStyles.getPropertyValue('--ion-color-primary');
    // }

    return bodyStyles.getPropertyValue('--ion-color-primary')
  }

  public getTime(pair: number) {
    switch (pair) {
      case 1: {
        return '09:00 - 10:30';
      }
      case 2: {
        return '10:40 - 12:10';
      }
      case 3: {
        return '12:50 - 14:20';
      }
      case 4: {
        return '14:30 - 16:00';
      }
      case 5: {
        return '16:10 - 17:40';
      }
      case 6: {
        return '17:50 - 19:20';
      }
      default: {
        return '';
      }
    }
  }
}
