import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { VisitsComponent } from './components/visits/visits.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { ThemeService } from './services/theme.service';
import { JwtInterceptor } from './services/jwt.interceptor';

export const getToken = () => localStorage.getItem('jwt');

@NgModule({
  declarations: [
    AppComponent,
    VisitsComponent,
  ],
  entryComponents: [VisitsComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({mode:'ios'}),
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [
    UserService,
    AuthGuard,
    ThemeService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

