import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-multy-toggle",
  templateUrl: "./multi-toggle.component.html",
  styleUrls: ["./multi-toggle.component.scss"],
})
export class MultiToggleComponent<T> implements OnInit {
  @Input() values: T[];
  @Input() currentValue: T;
  public valueIndex = 0;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("border", { static: false }) border: ElementRef;
  @ViewChild("bar", { static: false }) bar: ElementRef;

  private isTouch = false;
  private startTouch: number = 0;
  private startMove: number = 0;

  constructor() {}

  ngOnInit() {
    this.valueIndex = this.findIndexOfElement();

    //this.border.nativeElement.style.width = this.barRect().width * 2 + 'px';
    //= 300px;
  }

  ngAfterViewInit() {
    //this.bar.nativeElement.style.width = this.barRect().height + "px";
  }

  log(event: TouchEvent) {
    if (!this.isTouch) return;
    let newPosition =
      this.startMove + event.touches[0].clientX - this.startTouch;

    if (
      newPosition < 0 ||
      newPosition > this.borderRect().width - this.barRect().width
    )
      return;
    this.bar.nativeElement.style.left = newPosition + "px";
    this.findNewElement();
  }

  public touchStart(event: TouchEvent) {
    this.isTouch = true;
    this.startTouch = event.touches[0].clientX;
    this.startMove = this.barRect().left - this.borderRect().left;
  }

  public touchEnd(event: any) {
    this.isTouch = false;

    let borderWidth = this.borderRect().width - this.barRect().width;
    let part = borderWidth / (this.values.length - 1);

    this.bar.nativeElement.style.left = this.valueIndex * part + "px";
  }

  public getLeft() {
    let borderWidth = this.borderRect().width - this.barRect().width;
    let part = borderWidth / (this.values.length - 1);
    let barLeft = this.fromPx(this.bar.nativeElement.style.left);
    if (this.valueIndex > 0) {
      this.valueIndex--;
      this.currentValue = this.values[this.valueIndex];
      this.bar.nativeElement.style.left = barLeft - part + "px";

      this.change.emit(this.currentValue);
    }
  }

  public getRight() {
    let borderWidth = this.borderRect().width - this.barRect().width;
    let part = borderWidth / (this.values.length - 1);
    let barLeft = this.fromPx(this.bar.nativeElement.style.left);
    if (this.valueIndex < this.values.length - 1) {
      this.valueIndex++;
      this.currentValue = this.values[this.valueIndex];
      this.bar.nativeElement.style.left = barLeft + part + "px";

      this.change.emit(this.currentValue);
    }
  }

  private findNewElement() {
    let borderWidth = this.borderRect().width;
    let part = borderWidth / this.values.length;
    let newIndex = Math.trunc(
      (this.barRect().left -
        this.borderRect().left +
        this.barRect().width / 2) /
        part
    );
    if (newIndex != this.valueIndex) {
      this.valueIndex = newIndex;
      this.currentValue = this.values[newIndex];

      this.change.emit(this.currentValue);
    }
  }

  private findIndexOfElement() {
    let i = this.values.findIndex((value, index) => {
      return value === this.currentValue;
    });
    return i || 0;
  }

  private barRect(): ClientRect {
    return this.bar.nativeElement.getBoundingClientRect();
  }

  private barLeft(): number {
    return this.borderRect().left - this.barRect().left;
  }

  private borderRect(): ClientRect {
    return this.border.nativeElement.getBoundingClientRect();
  }

  private fromPx(val: string): number {
    if (!val) return 0;
    let num = val.replace("px", "");
    return Number.parseFloat(num);
  }
}
