import { Animation, AnimationController } from '@ionic/angular';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  static fieldID = 0;

  @Input() label = '';
  @Input() type = '';
  @Input() name = '';
  @ViewChild('floatingLabel') labelElement: ElementRef;
  @Output() valueChange = new EventEmitter<string>();

  public isShowLabel: boolean;
  public placeHolder: string;
  public floatingLabelId: string;

  constructor(private animationCtrl: AnimationController) {
    this.floatingLabelId = 'floatingLabel_' + InputFieldComponent.fieldID;
    InputFieldComponent.fieldID++;
  }

  ngOnInit() {
    this.placeHolder = this.label;
  }

  public onChange(event: any) {
    const value = event.detail.value;
    this.valueChange.emit(value);
  }

  public onFocus() {
    this.placeHolder = '';

    const animationOpen: Animation = this.animationCtrl
      .create()
      .addElement(document.getElementById(this.floatingLabelId))
      .duration(200)
      .fromTo('transform', 'translateY(0px)', 'translateY(-5px)')
      .fromTo('transform', 'translateX(5px)', 'translateX(0px)')
      .fromTo('opacity', '0', '1');

    animationOpen.play();
  }

  public onBlur() {
    this.placeHolder = this.label;

    const animationClose: Animation = this.animationCtrl
      .create()
      .addElement(document.getElementById(this.floatingLabelId))
      .duration(200)
      .fromTo('transform', 'translateY(-5px)', 'translateY(0px)')
      .fromTo('transform', 'translateX(0px)', 'translateX(5px)')
      .fromTo('opacity', '1', '0');

    animationClose.play();
  }
}
