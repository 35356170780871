import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollableComponent } from './scrollable/scrollable.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { ReadonlyFieldComponent } from './readonly-field/readonly-field.component';
import { ToggleComponent } from './toggle/toggle.component';
import { MultiToggleComponent } from './multi-toggle/multi-toggle.component';
import { SubjectComponent } from './subject/subject.component';
import { LoadTextPipe } from '../services/text.service';
import { AppToastComponent } from './toast/app-toast.component';
import { ExamSubjectComponent } from './exam-subject/exam-subject.component';
import { InputFieldComponent } from './input-field/input-field.component';

@NgModule({
  declarations: [
    ScrollableComponent,
    HeaderComponent,
    ReadonlyFieldComponent,
    ToggleComponent,
    MultiToggleComponent,
    SubjectComponent,
    LoadTextPipe,
    AppToastComponent,
    ExamSubjectComponent,
    InputFieldComponent
  ],
  imports: [CommonModule, IonicModule, RouterModule],
  exports: [
    ScrollableComponent,
    HeaderComponent,
    ReadonlyFieldComponent,
    ToggleComponent,
    MultiToggleComponent,
    SubjectComponent,
    LoadTextPipe,
    AppToastComponent,
    InputFieldComponent,
    ExamSubjectComponent
  ],
})
export class ComponentsModule {}
