import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { SubjectPersonalVisit } from 'src/app/models/visit';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  visits: Array<SubjectPersonalVisit>
  subject: string;

  constructor(
    navParams: NavParams,
    private modalController: ModalController,
    ) { 
    this.visits = navParams.get('visits');
    this.subject = navParams.get('subject');
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  formatDate(date){
    var d = new Date(Date.parse(date));
    return d.toLocaleDateString();
  }
}
